<template>
  <v-container fluid class="pa-0">
    <v-row class="pb-2" v-if="invoiceIsFetchingPayment">
      <v-col cols="12">
        <v-progress-linear color="primary" absolute indeterminate></v-progress-linear>
      </v-col>
    </v-row>
    <div v-for="(invoicePayment, index) in invoiceDetailsPayment?.invoicePayments" :key="index">
      <div class="pa-4">
        <v-row no-gutters>
          <v-col cols="2" class="d-flex justify-start align-center">
            <span class="font-weight-bold title">{{ fundingSourceLabel }} #{{ index + 1 }}</span>
          </v-col>

          <v-col cols="6" class="d-flex align-center">
            <v-col cols="11" class="d-flex align-center">
              <div>
                <div class="body-3 grey--text text--darken-1">
                  {{ fundingSourceLabel }}: {{ invoicePayment.fundingSourceName || 'None' }}
                </div>
                <div class="body-3 grey--text text--darken-1">
                  <span>
                    Budget Code:
                    <span>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            small
                            left
                            v-if="invoicePayment.isBudgetCodeDeleted"
                            v-bind="attrs"
                            v-on="on"
                            color="red"
                          >
                            mdi-alert-circle
                          </v-icon>
                        </template>
                        <span v-if="invoicePayment.isBudgetCodeDeleted">{{ removedBudgetCodeMessage }}</span>
                      </v-tooltip>
                    </span>
                    <span>{{ invoicePayment.budgetCode || 'N/A' }}</span>
                  </span>
                </div>
              </div>
            </v-col>
          </v-col>
          <v-col cols="4" class="d-flex payment-row">
            <div class="d-flex flex-row align-center">
              <div v-if="invoicePayment.fundingSourceType === 'percentage'" class="d-flex flex-row align-center">
                <div class="body-3 funding-source-type">
                  {{ invoicePayment.amount + '%' }}
                </div>
                <v-text-field
                  class="amount-field mx-2"
                  :ref="'amountField_' + index"
                  :disabled="isInvoicePaymentReadOnly(invoicePayment)"
                  placeholder="0.00"
                  prefix="$"
                  outlined
                  readonly
                  :value="percentageValue({ amount: invoicePayment.amount, total: invoiceTotalCosts.total })"
                  v-bind="inputProps"
                ></v-text-field>
              </div>
              <div v-else class="d-flex flex-row align-center">
                <div class="body-3 funding-source-type">
                  {{ convertSnakeCaseToNormalCase(invoicePayment.fundingSourceType) }}
                </div>
                <v-text-field
                  class="amount-field mx-2"
                  :ref="'amountField_' + index"
                  :disabled="isInvoicePaymentReadOnly(invoicePayment)"
                  placeholder="0.00"
                  prefix="$"
                  label="Amount"
                  type="number"
                  :value="invoicePayment.amount"
                  v-bind="inputProps"
                  @change="onChangeOfAmount($event, invoicePayment)"
                ></v-text-field>
              </div>
              <v-btn fab text small color="primary" @click="editPayment(invoicePayment)" v-if="isEditable">
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
            </div>
          </v-col>
        </v-row>
        <v-row no-gutters class="my-2">
          <v-spacer></v-spacer>
          <v-col cols="4" class="d-flex flex-row align-center justify-end">
            <v-text-field
              class="check-number-field mx-2"
              :ref="'checkNumberField_' + index"
              :disabled="!canEditCheckNumber(invoicePayment)"
              type="text"
              label="Check Number"
              :value="invoicePayment.checkNumber"
              v-bind="inputProps"
              maxlength="10"
              @change="updateInvoicePayment({ ...invoicePayment, checkNumber: $event })"
            />
            <v-text-field
              class="batch-journal-number-field mx-2"
              :ref="'batchJournalNumberField_' + index"
              :disabled="!canEditBatchJournalNumber"
              type="text"
              label="Batch/Journal #"
              :value="invoicePayment.batchJournalNumber"
              v-bind="inputProps"
              maxlength="15"
              @change="updateInvoicePayment({ ...invoicePayment, batchJournalNumber: $event })"
            />
            <div class="mx-2">
              <ChipsDropdown
                :items="filteredInvoiceStatusOptions(invoicePayment)"
                :canUpdate="() => canUpdateInvoicePaymentStatus(invoicePayment)"
                :statusConfig="statusConfig"
                :value="invoicePayment.status"
                @input="onChipStatusSelect($event, invoicePayment)"
                :disabled="isDoNotBill && !canManageInvoice"
                :key="`${index}_${chipKey}`"
              />
            </div>
            <v-btn
              fab
              text
              small
              color="red"
              @click="deleteInvoicePayment(invoicePayment, index)"
              v-if="!invoiceReadOnly"
            >
              <v-icon small>mdi-delete</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </div>
      <v-divider class="divider mb-3"></v-divider>
    </div>
    <v-row no-gutters class="pt-4">
      <v-col cols="3">
        <v-btn plain @click="addFundingSource" v-if="canAddMore">
          <v-icon left>mdi-plus</v-icon> {{ fundingSourceLabel }}
        </v-btn>
      </v-col>
      <v-col cols="8" class="d-flex justify-end pr-5">Total:</v-col>
      <v-col cols="1">$ {{ parseFloat(total).toFixed(2) }}</v-col>
    </v-row>
    <v-row no-gutters class="pa-4">
      <v-col cols="12" class="d-flex justify-end">
        <v-btn color="blue" class="white--text" @click="onStatusClick" :disabled="isApproveDisabled">
          {{ paymentStatus.text === 'Pending' ? 'Approve All' : 'Revert To Pending' }}
        </v-btn>
      </v-col>
    </v-row>
    <InvoiceDetailsPaymentFundingSourceDialog
      v-if="addFundingSourceDialog.visible"
      :visible.sync="addFundingSourceDialog.visible"
      :data="editPaymentData"
      :invoiceId="invoiceId"
    />
    <CustomFormFieldsDisplay
      :is-readonly="invoiceReadOnly"
      :section="'Payment'"
      :parentId="invoiceId"
      class="pt-4"
    ></CustomFormFieldsDisplay>
  </v-container>
</template>
<script>
import { inputProps, convertSnakeCaseToNormalCase, percentageValue } from '@/util';
import { mapActions, mapGetters } from 'vuex';
import { required, numeric, decimal } from '@/util/rules';
import InvoiceDetailsPaymentFundingSourceDialog from '@/components/Invoice/details/InvoiceDetailsPaymentFundingSourceDialog.vue';
import { CustomFormFieldsDisplay, ChipsDropdown } from '@/components/shared';
import { fundingSourceMap } from '@/util/enums';
import { removedBudgetCodeMessage } from '@/util/messages';
import { fundingSourceTypesMap } from '@/util/constants';

export default {
  components: {
    InvoiceDetailsPaymentFundingSourceDialog,
    CustomFormFieldsDisplay,
    ChipsDropdown,
  },
  props: {
    invoiceId: { type: Number, default: 0 },
  },
  data() {
    return {
      required,
      numeric,
      decimal,
      inputProps,
      convertSnakeCaseToNormalCase,
      addFundingSourceDialog: {
        visible: false,
      },
      editPaymentData: {},
      removedBudgetCodeMessage,
      fundingSourceTypesMap,
      currentPayment: null,
      budgetCode: {},
      chipKey: 0,
    };
  },
  mounted() {
    this.init();
  },
  computed: {
    ...mapGetters('user', ['me', 'isAdmin', 'canManageInvoice', 'isVehicleOwnerEligible']),
    ...mapGetters('invoicePayment', ['fundingSourceTypes']),
    ...mapGetters('invoice', [
      'invoiceReadOnly',
      'invoiceDetailsPayment',
      'invoiceIsFetchingPayment',
      'invoiceTotalCosts',
      'selectedInvoice',
      'canUpdateInvoicePaymentStatus',
      'statusConfig',
      'getFilteredInvoiceStatusOptions',
    ]),
    ...mapGetters('config', ['tripRequestConfig', 'permissions']),
    ...mapGetters('tripRequest', ['currentTripRequest']),
    ...mapGetters('fundingSource', ['fundingSourcesById', 'fundingSources', 'filterFundingSources']),
    isApproveDisabled() {
      if (this.isAdmin) return false;
      if (this.me.is.schoolFinance) return true;

      const readonly = this.invoiceReadOnly || !this.isFullPayment;
      if (readonly) return true;

      if (this.isVehicleOwnerEligible) return false;

      return true;
    },
    isApproved() {
      return this.selectedInvoice.status == 'approved';
    },
    isDoNotBill() {
      return this.selectedInvoice.status == 'do_not_bill';
    },
    editableFundingSource() {
      return fundingSourceMap.type.editable;
    },
    fundingSourceLabel() {
      return this.tripRequestConfig.labels?.addFundingSource || 'Funding Source';
    },
    filteredFundingSources() {
      return this.filterFundingSources({
        tripTypeId: this.invoiceDetailsPayment.assignment.tripRequest.tripTypeId,
        locationId: this.invoiceDetailsPayment.assignment.tripRequest.locationId,
        existingFundingSourceIds: new Set(),
        selected: null,
      });
    },
    paymentStatus() {
      if (this.approved) {
        return { text: 'Approved', color: 'success' };
      }
      return { text: 'Pending', color: 'default' };
    },
    canEditBatchJournalNumber() {
      return this.isAdmin || this.me.is.finance;
    },
    total() {
      let total = 0;

      if (!this.invoiceDetailsPayment?.invoicePayments?.length) {
        return this.invoiceTotalCosts.total || total;
      }

      for (const payment of this.invoiceDetailsPayment?.invoicePayments || []) {
        if (payment.fundingSourceType === 'percentage') {
          total += +percentageValue({ amount: payment.amount, total: this.invoiceTotalCosts.total }) || 0;
        } else {
          total += +payment.amount || 0;
        }
      }
      return total;
    },
    isFullPayment() {
      return +this.total === +this.invoiceTotalCosts.total;
    },
    isFirstPayment() {
      return this.invoiceDetailsPayment?.invoicePayments?.length === 1;
    },
    approved() {
      return !!this.invoiceDetailsPayment?.isPaymentApproved;
    },
    canAddMore() {
      const invoicePayments = this.invoiceDetailsPayment?.invoicePayments || [];
      const fundingSourceLimit = this.tripRequestConfig.display.fundingSourceLimit || 1;
      return !this.invoiceReadOnly && invoicePayments.length < fundingSourceLimit;
    },
    isEditable() {
      const { fundingManager, schoolFinance } = this.me.is;
      return !(fundingManager || schoolFinance) && !this.invoiceReadOnly;
    },
  },
  methods: {
    ...mapActions('invoice', ['getInvoiceDetails', 'saveInvoice', 'saveInvoicePayment', 'deleteInvoicePaymentById']),
    isInvoicePaymentReadOnly(invoicePayment) {
      return this.invoiceReadOnly || this.isFirstPayment || !invoicePayment.permission.canUpdate;
    },
    canEditCheckNumber(invoicePayment) {
      return this.isAdmin || invoicePayment.permission.canUpdate;
    },
    filteredInvoiceStatusOptions(payment) {
      return this.getFilteredInvoiceStatusOptions(payment.status);
    },
    async updateInvoicePayment(invoicePayment) {
      try {
        await this.saveInvoicePayment({
          invoiceId: this.invoiceDetailsPayment.id,
          body: invoicePayment,
        });
      } catch (e) {
        this.$myalert.error('A problem occurred while saving the payment details.', true);
      }
    },
    async init() {
      await this.getInvoiceDetails({ tab: 'payment', invoiceId: this.invoiceId });

      if (
        this.invoiceDetailsPayment?.invoicePayments.length === 1 &&
        this.invoiceDetailsPayment?.status === 'pending' &&
        this.invoiceDetailsPayment?.invoicePayments[0].status === 'pending' &&
        this.invoiceDetailsPayment?.invoicePayments[0].fundingSourceType !== 'percentage'
      ) {
        this.onChangeOfAmount(this.invoiceTotalCosts.total, this.invoiceDetailsPayment.invoicePayments[0]);
      }
    },
    async onChangeOfSplitType(value, invoicePayment) {
      const body = {
        id: invoicePayment.id,
        fundingSourceType: value,
      };

      if (
        (invoicePayment.fundingSourceType === 'percentage' && value !== 'percentage') ||
        (invoicePayment.fundingSourceType !== 'percentage' && value === 'percentage')
      ) {
        body.amount = 0;
      }
      await this.saveInvoicePayment({
        invoiceId: this.invoiceDetailsPayment.id,
        body,
      });
    },
    async onChangeOfFundingSource(value, invoicePayment) {
      const fundingSource = this.fundingSources.find((fundingSource) => fundingSource.name === value);
      const body = {
        id: invoicePayment.id,
        fundingSourceId: fundingSource.id,
      };

      await this.saveInvoicePayment({
        invoiceId: this.invoiceDetailsPayment.id,
        body,
      });
    },
    async onChangeOfAmount(value, invoicePayment) {
      await this.saveInvoicePayment({
        invoiceId: this.invoiceDetailsPayment.id,
        body: {
          id: invoicePayment.id,
          amount: value,
        },
      });
    },
    async onChipStatusSelect(e, item) {
      let ok = true;
      if (e === 'do_not_bill') {
        ok = await this.$myconfirm('Are you sure you want to want to set this invoice to Do Not Bill?');
      } else if (item.status === 'do_not_bill' && e !== 'do_not_bill') {
        // Handle changing from Do Not Bill to something else
        ok = await this.$myconfirm('Are you sure you want to want to activate this invoice?');
      }

      if (!ok) {
        this.chipKey++; // This forces the chip component to retain the previous value
        return;
      }

      await this.saveInvoicePayment({
        invoiceId: item.invoiceId,
        body: { id: item.id, status: e },
      });
      await this.getInvoiceDetails({ tab: 'invoice', invoiceId: this.invoiceId });
    },
    percentageValue,
    addFundingSource() {
      this.editPaymentData = {};
      this.addFundingSourceDialog.visible = true;
    },
    editPayment(invoicePayment) {
      this.editPaymentData = invoicePayment;
      this.addFundingSourceDialog.visible = true;
    },
    async onStatusClick() {
      const newValue = !this.approved;
      const message = newValue
        ? 'Are you sure you want to confirm and approve all payments?'
        : 'Are you sure you want to revert the status to pending?';

      const ok = await this.$myconfirm(message);
      if (ok) {
        await this.saveInvoice({
          invoiceId: this.invoiceDetailsPayment.id,
          body: {
            isPaymentApproved: newValue,
            status: newValue ? 'approved' : 'pending',
          },
        });
        await this.saveInvoicePayment({
          invoiceId: this.invoiceDetailsPayment.id,
          body: { status: newValue ? 'approved' : 'pending', updateBatch: true },
        });
        await this.init();
      }
    },
    async deleteInvoicePayment(invoicePayment, index) {
      const ok = await this.$myconfirm(`Are you sure you want delete ${this.fundingSourceLabel} #${index + 1}?`);
      if (ok) {
        await this.deleteInvoicePaymentById({
          invoiceId: invoicePayment.invoiceId,
          id: invoicePayment.id,
        });
      }
    },
  },
};
</script>
<style scoped>
.payment-row {
  justify-content: flex-end;
}

.funding-source-type {
  white-space: nowrap;
  max-width: 200px;
  font-size: large;
}

.amount-field {
  max-width: 200px;
}

.check-number-field {
  max-width: 150px;
}

.batch-journal-number-field {
  max-width: 160px;
}

.divider {
  background-color: #d3d3d3;
}
</style>
