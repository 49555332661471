<template>
  <v-container fluid>
    <section v-for="staffType in staffTypes" :key="staffType">
      <div class="py-3">
        <v-row dense>
          <v-col>
            <v-select
              @change="saveAssignmentDriverAssistant({ staffType })"
              :disabled="invoiceReadOnly"
              :items="staffs"
              v-bind="inputProps"
              v-model="staff[staffType]"
              :label="staffType"
              :rules="[rules.required]"
              clearable
            />
          </v-col>

          <v-col>
            <div v-if="invoiceDetailsStaffCost" class="grey--text text-caption">
              <v-row v-if="retirementMap[staffType]" dense>
                <v-col cols="4">{{ staffType }} ID</v-col>
                <v-col cols="2">{{ invoiceDetailsStaffCost[`${staffType.toLowerCase()}Id`] || 0 }}</v-col>
                <v-col cols="4" v-if="invoiceConfig.calculateBenefitsWithDriverRate">Retirement</v-col>
                <v-col cols="2" v-if="invoiceConfig.calculateBenefitsWithDriverRate">
                  ${{ Number(invoiceStaffBenefits[staffType.toLowerCase()].retirement).toFixed(2) }}
                </v-col>
              </v-row>

              <v-row dense v-if="invoiceConfig.calculateBenefitsWithDriverRate">
                <v-col cols="4">SS</v-col>
                <v-col cols="2"> ${{ Number(invoiceStaffBenefits[staffType.toLowerCase()].ss).toFixed(2) }} </v-col>
                <v-col cols="4">Workers Comp</v-col>
                <v-col cols="2">
                  ${{ Number(invoiceStaffBenefits[staffType.toLowerCase()].workerComp).toFixed(2) }}
                </v-col>
              </v-row>
              <v-row dense v-if="invoiceConfig.calculateBenefitsWithDriverRate">
                <v-col cols="4">Medicare</v-col>
                <v-col cols="2">${{ Number(invoiceStaffBenefits[staffType.toLowerCase()].medical).toFixed(2) }}</v-col>
                <v-col></v-col>
                <v-col></v-col>
              </v-row>
            </div>
          </v-col>

          <v-col></v-col>
        </v-row>

        <v-form v-model="staffForm.valid" :ref="`staffCostForm${staffType}`" class="my-4">
          <Table
            :menuOptions="menuOptions"
            :itemsPerPage="-1"
            :color="colors.lightGreen"
            sortable
            :headers="updatedHeaders"
            :rows="getRows(staffType)"
            :loading="invoiceIsFetchingStaffCost"
          >
            <template #footer>
              <tfoot>
                <tr v-if="showAddNewRow === staffType" class="height-adjust">
                  <th>
                    <DatePicker
                      :rules="[
                        rules.required,
                        rules.minDate(minimumDate, rules.dateStringMessage),
                        rules.maxDate(maximumDate, rules.dateStringMessage),
                      ]"
                      v-bind="tableInputProps"
                      label="Date"
                      v-model="staffForm.date"
                    />
                  </th>
                  <th v-if="showStartEndTime">
                    <TimePicker
                      v-bind="tableInputProps"
                      label="Start Time"
                      v-model="staffForm.startTime"
                      @input="updateHours(staffForm)"
                    />
                  </th>
                  <th v-if="showStartEndTime">
                    <TimePicker
                      v-bind="tableInputProps"
                      label="End Time"
                      v-model="staffForm.endTime"
                      @input="updateHours(staffForm)"
                    />
                  </th>
                  <th>
                    <v-text-field
                      :rules="[rules.required, rules.decimal]"
                      label="Hours"
                      v-bind="tableInputProps"
                      v-model="staffForm.hours"
                    />
                  </th>
                  <th v-if="showDistrictRateType">
                    <v-select
                      label="District Wide Rate Type"
                      :rules="[rules.required]"
                      v-bind="tableInputProps"
                      v-model="staffForm.districtRateType"
                      :items="enumToArray(districtRateTypes)"
                      @change="setHourlyRate({ form: staffForm, staff: currentStaff[staffType], staffType })"
                    />
                  </th>
                  <th>
                    <v-select
                      label="Rate Type"
                      :rules="[rules.required]"
                      v-bind="tableInputProps"
                      v-model="staffForm.rateType"
                      :items="enumToArray(staffCostMap.rateType)"
                      @change="setHourlyRate({ form: staffForm, staff: currentStaff[staffType], staffType })"
                    />
                  </th>
                  <th>
                    <v-text-field
                      :rules="[rules.required, rules.decimal]"
                      label="Hourly Rate"
                      v-bind="tableInputProps"
                      v-model="staffForm.hourlyRate"
                    />
                  </th>
                  <th>
                    <v-text-field
                      :rules="[rules.required]"
                      label="Break (minutes)"
                      v-bind="tableInputProps"
                      v-model="staffForm.breaks"
                      type="number"
                    >
                      <template #append-outer>
                        <InvoiceDetailsBreakCalculator v-model="staffForm.breaks" />
                      </template>
                    </v-text-field>
                  </th>
                  <th class="text-right">{{ computeCost(staffForm) ?? '' }}</th>
                  <th></th>
                </tr>
                <tr v-if="showAddNewRow === staffType">
                  <th :colspan="updatedHeaders.length + 1" class="text-center">
                    <v-btn
                      :disabled="invoiceIsFetchingStaffCost"
                      small
                      tile
                      depressed
                      color="success"
                      class="mr-2"
                      @click="saveStaffCost({ form: staffForm })"
                      >Save</v-btn
                    >
                    <v-btn
                      :disabled="invoiceIsFetchingStaffCost"
                      small
                      tile
                      depressed
                      color="error"
                      @click="hideStaffCostForm"
                      >Cancel</v-btn
                    >
                  </th>
                </tr>
                <tr>
                  <th>
                    <v-btn
                      text
                      @click="showStaffCostForm(staffType)"
                      :disabled="!!showAddNewRow"
                      v-if="!invoiceReadOnly"
                    >
                      <v-icon left>mdi-plus</v-icon>
                      New Line
                    </v-btn>
                  </th>
                  <th :colspan="updatedHeaders.length - (showStartEndTime ? 2 : 3)"></th>
                  <th class="text-right" v-if="invoiceConfig.calculateBenefitsWithDriverRate">
                    Benefits: $ {{ getTotalBenefit(staffType).toFixed(2) }}
                  </th>
                  <th class="text-right">Total: ${{ totalCost[staffType] }}</th>
                </tr>
              </tfoot>
            </template>
          </Table>
        </v-form>
      </div>
    </section>

    <InvoiceDetailsEditStaffCost
      v-if="editDialog.visible"
      :details="editDialog.details"
      :visible.sync="editDialog.visible"
      :updateHours="updateHours"
      :computeCost="computeCost"
      :save="saveStaffCost"
      :invoiceRate="invoiceConfig.invoiceRate"
      :districtRateTypes="districtRateTypes"
      :setHourlyRate="setHourlyRate"
      :currentStaff="currentStaff"
      :show-start-end-time="showStartEndTime"
      :date-validation-details="{ minimumDate, maximumDate, dateStringMessage: rules.dateStringMessage }"
      @update:visible="hideStaffCostForm"
    />
    <CustomFormFieldsDisplay
      :is-readonly="invoiceReadOnly"
      :section="'Staff Costs'"
      :parentId="invoiceId"
    ></CustomFormFieldsDisplay>
  </v-container>
</template>
<script>
import { Table } from '@/components/shared';
import { inputProps, colors, formatTime, toDateString, toNumberString, toTimeString } from '@/util';
import DatePicker from '@/components/DatePicker';
import TimePicker from '@/components/TimePicker';
import { staffCostMap, enumToArray } from '@/util/enums';
import { required, min, minDate, maxDate, decimal } from '@/util/rules';
import InvoiceDetailsEditStaffCost from '@/components/Invoice/details/InvoiceDetailsEditStaffCost';
import InvoiceDetailsBreakCalculator from '@/components/Invoice/details/InvoiceDetailsBreakCalculator';
import { CustomFormFieldsDisplay } from '@/components/shared';
import { getTimeDifferenceInMinutes } from '@common/invoice/staff-cost';

import { mapActions, mapGetters, mapMutations } from 'vuex';
export default {
  components: {
    Table,
    DatePicker,
    TimePicker,
    InvoiceDetailsEditStaffCost,
    InvoiceDetailsBreakCalculator,
    CustomFormFieldsDisplay,
  },
  props: {
    invoiceId: { type: Number, default: 0 },
    assignmentId: { type: Number, default: 0 },
  },
  data() {
    return {
      staffCostMap,
      rules: {
        required,
        min,
        minDate,
        maxDate,
        decimal,
        dateStringMessage: 'Date is too far from the original trip date',
      },
      staff: {
        Driver: null,
        Assistant: null,
      },
      inputProps,
      tableInputProps: { ...inputProps, dense: true },
      colors,
      showAddNewRow: null,
      editDialog: {
        visible: false,
        details: null,
      },
      staffForm: {
        date: null,
        startTime: null,
        endTime: null,
        hours: null,
        districtRateType: null,
        rateType: null,
        hourlyRate: null,
        breaks: 0,
        valid: false,
      },
      headers: [
        {
          text: 'Date',
          value: 'data',
          render: (item) => {
            return toDateString(item.date);
          },
        },
        {
          text: 'Start Time',
          value: 'startTime',
          show: () => this.showStartEndTime,
          render: (item) => {
            return toTimeString(item.startTime) || '';
          },
        },
        {
          text: 'End Time',
          value: 'endTime',
          show: () => this.showStartEndTime,
          render: (item) => {
            return toTimeString(item.endTime) || '';
          },
        },
        { text: 'Hours', value: 'hours' },
        { text: 'District Wide Rate Type', value: 'districtRateType', show: () => this.showDistrictRateType },
        {
          text: 'Rate Type',
          value: 'rateType',
          render: (item) => {
            return staffCostMap.rateType[item.rateType] || '';
          },
        },
        {
          text: 'Hourly Rate',
          value: 'hourlyRate',
          render: (item) => {
            return `$${toNumberString(item.hourlyRate)}`;
          },
        },
        {
          text: 'Break',
          value: 'breaks',
          render: (item) => {
            return `${toNumberString(item.breaks)} min`;
          },
        },
        {
          text: 'Costs',
          value: 'total',
          render: (item) => {
            return `$${toNumberString(item.total)}`;
          },
        },
      ],
      menuOptions: [
        {
          text: 'Edit',
          click: (item) => {
            this.setEditDialogVisibility({
              visible: true,
              details: {
                staffCostMap: item,
                invoiceId: this.invoiceId,
              },
            });
          },
          disabled: () => this.invoiceReadOnly,
          icon: 'mdi-pencil',
        },
        {
          text: 'Delete',
          click: (item) => {
            this.deleteInvoiceStaffById({
              invoiceId: this.invoiceId,
              staffCostId: item.id,
            });
          },
          disabled: () => this.invoiceReadOnly,
          icon: 'mdi-delete',
        },
      ],
      rows: [],
    };
  },
  computed: {
    ...mapGetters('invoice', [
      'invoiceReadOnly',
      'invoiceDetailsStaffCost',
      'invoiceStaffBenefits',
      'invoiceIsFetchingStaffCost',
      'selectedInvoice',
    ]),
    ...mapGetters('staff', ['staffList']),
    ...mapGetters('config', ['invoiceConfig', 'districtWideRates']),
    ...mapGetters('tripRequest', ['currentTripRequest']),
    minimumDate() {
      const newMinimumDate = new Date(this.invoiceDetailsStaffCost.leaveDate.split('T')[0]);
      newMinimumDate.setDate(newMinimumDate.getDate() - 7);
      return newMinimumDate.toISOString();
    },
    maximumDate() {
      const newMaximumDate = new Date(this.invoiceDetailsStaffCost.returnDate.split('T')[0]);
      newMaximumDate.setDate(newMaximumDate.getDate() + 7);
      return newMaximumDate.toISOString();
    },
    retirementMap() {
      return {
        Driver: this.invoiceDetailsStaffCost?.driverRetirement === 1,
        Assistant: this.invoiceDetailsStaffCost?.assistantRetirement === 1,
      };
    },
    currentStaff() {
      const staffMap = {};
      this.staffTypes.forEach((staffType) => {
        const staffId = this.staff[staffType];
        staffMap[staffType] = this.staffList.find((staff) => staff.id === staffId);
      });

      return staffMap;
    },
    staffs() {
      const manualDrivers = this.currentTripRequest?.assignments?.filter(
        (a) => a.driverId === 0 && a.id === this.selectedInvoice.assignmentId
      );

      return [...manualDrivers, ...this.staffList].map((staff) => {
        const name = staff.driverId === 0 ? staff.driver : `${staff.lastName}, ${staff.firstName}`;
        return { text: `${name}`, value: staff.driverId === 0 ? 0 : staff.id };
      });
    },
    formRefWithStaffType() {
      if (!this.showAddNewRow) return null;
      return this.$refs[`staffCostForm${this.showAddNewRow}`][0];
    },
    totalCost() {
      if (!this.invoiceDetailsStaffCost) return {};

      const types = Object.values(staffCostMap.staffType);
      const totals = {};

      types.forEach((type) => {
        const costs = this.invoiceDetailsStaffCost.invoiceStaffs.filter(
          (staff) => staff.staffType === type.toLowerCase()
        );
        const total =
          costs.reduce((accumulator, currentValue) => {
            return accumulator + +currentValue.total;
          }, 0) + this.getTotalBenefit(type);
        totals[type] = parseFloat(total).toFixed(2);
      });

      return totals;
    },
    staffTypes() {
      return Object.values(staffCostMap.staffType);
    },
    updatedHeaders() {
      const newHeaders = [...this.headers];

      return newHeaders.filter((header) => {
        if (typeof header.show === 'function') {
          return header.show();
        }
        return true;
      });
    },
    showDistrictRateType() {
      return this.invoiceConfig.invoiceRate;
    },
    showStartEndTime() {
      return this.invoiceConfig.displayActualDriverTime;
    },
    districtRateTypes() {
      const rateTypeNames = {};

      this.invoiceConfig.districtWideRates?.forEach((rateType) => {
        rateTypeNames[`${rateType.name}`] = `${rateType.name}`;
      });

      return rateTypeNames;
    },
  },
  async mounted() {
    Promise.all([
      this.getInvoiceDetails({ tab: 'staff_cost', invoiceId: this.invoiceId }),
      this.getStaffs({ active: true }),
      this.getConfig('invoice'),
      this.getDistrictWideRates(),
    ]).then(() => {
      this.setDriverAssistantForm();
      this.setStaffCostDefault();
    });
  },
  methods: {
    ...mapActions('invoice', ['getInvoiceDetails', 'saveInvoiceStaffCost', 'deleteInvoiceStaffById']),
    ...mapActions('staff', ['getStaffs']),
    ...mapActions('assignment', ['updateAssignmentDriverAssistantById', 'getAssignments']),
    ...mapActions('config', ['getConfig', 'getDistrictWideRates']),
    ...mapMutations('invoice', ['setSelectedInvoiceDriverAssistant']),
    enumToArray,
    async saveAssignmentDriverAssistant({ staffType }) {
      const staffId = this.staff[staffType] || 0;
      const params = {
        assignmentId: this.assignmentId,
        driverId: staffType === 'Driver' ? staffId : undefined,
        assistantId: staffType === 'Assistant' ? staffId : undefined,
      };

      const staffName = this.staffs.find((staff) => staff.value === staffId)?.text || 'N/A';
      try {
        await this.updateAssignmentDriverAssistantById(params);
        this.setSelectedInvoiceDriverAssistant({
          ...params,
          driver: staffType === 'Driver' ? staffName : undefined,
          assistant: staffType === 'Assistant' ? staffName : undefined,
        });
      } catch (e) {
        this.$myalert.error('A problem occurred while updating staff details.', true);
      }
    },
    updateHours(form) {
      if (form.startTime && form.endTime) {
        form.hours = (getTimeDifferenceInMinutes(form.date, form.startTime, form.endTime) / 60).toFixed(2);
      }
    },
    computeCost(data) {
      if (!data.hours) return 0;
      if (!data.hourlyRate) return 0;

      const durationMinutes = data.hours * 60;
      const adjustedDuration = data.breaks ? durationMinutes - data.breaks : durationMinutes;
      const cost = (adjustedDuration / 60) * data.hourlyRate;

      return cost.toFixed(2);
    },

    setEditDialogVisibility({ visible, details }) {
      this.hideStaffCostForm();

      this.editDialog.visible = visible;
      if (!visible) return;
      const clonedDetails = JSON.parse(JSON.stringify(details));

      if (clonedDetails.staffCostMap.startTime && clonedDetails.staffCostMap.endTime) {
        clonedDetails.staffCostMap.startTime = clonedDetails.staffCostMap.startTime.replace(/^0/, '');
        clonedDetails.staffCostMap.endTime = clonedDetails.staffCostMap.endTime.replace(/^0/, '');
      }

      if (clonedDetails.staffCostMap.date)
        clonedDetails.staffCostMap.date = clonedDetails.staffCostMap.date.split('T')[0];

      this.showAddNewRow = clonedDetails.staffCostMap.staffType;

      this.editDialog.details = clonedDetails;
    },

    getRows(staffType) {
      if (!staffType) return [];
      if (!this.invoiceDetailsStaffCost) return [];

      const costs = this.invoiceDetailsStaffCost.invoiceStaffs;

      if (!costs && !costs.length) return [];

      const rows = costs.filter((item) => item.staffType === staffType.toLowerCase());

      if (!rows && !rows.length) return [];

      return rows;
    },
    validateForm({ staffType, form }) {
      const { date, hours, hourlyRate, rateType } = form;

      const showError = (message) => {
        this.$myalert.error(message, true);
        return false;
      };
      const isValidDate = (dateString) => dateString && !isNaN(new Date(dateString).getTime());

      const formDate = new Date(date);

      const leaveDateString = this.minimumDate;
      const returnDateString = this.maximumDate;

      const leaveDate = isValidDate(leaveDateString) ? new Date(leaveDateString) : null;
      const returnDate = isValidDate(returnDateString) ? new Date(returnDateString) : null;

      if (this.showStartEndTime) {
        if (!leaveDate || !returnDate) {
          return showError('Invalid trip leave date or return date.');
        }
        if (formDate < leaveDate || formDate > returnDate) {
          return showError(this.rules.dateStringMessage);
        }
      }

      const requiredFields = [
        { value: date, message: 'Date is required.' },
        { value: rateType, message: 'Rate type is required.' },
        { value: hourlyRate, message: 'Hourly rate is required.' },
        { value: hours, message: 'Hours is required.' },
      ];

      for (const field of requiredFields) {
        if (!field.value) {
          return showError(field.message);
        }
      }

      return true;
    },
    saveStaffCost({ form, novalidate = false, staffType }) {
      const actualStaffType = staffType || (this.showAddNewRow ? this.showAddNewRow.toLowerCase() : null);

      if (!novalidate) {
        if (!actualStaffType) {
          this.$myalert.error('No staff type provided. Please select a valid staff type.', true);
          return;
        }

        const isFormValid = this.formRefWithStaffType.validate();

        if (!isFormValid) {
          this.$myalert.error('Please complete all required fields.', true);
          return;
        }

        const isValid = this.validateForm({ staffType: actualStaffType, form });
        if (!isValid) {
          return;
        }
      }

      const cleanForm = {
        ...form,
        staffType: actualStaffType,
        startTime: this.showStartEndTime && form.startTime ? formatTime(form.startTime) : null,
        endTime: this.showStartEndTime && form.endTime ? formatTime(form.endTime) : null,
        hours: form.hours,
      };
      delete cleanForm.valid;

      this.saveInvoiceStaffCost({
        body: [cleanForm],
        invoiceId: this.invoiceId,
      })
        .then(() => {
          this.$myalert.success('Staff cost saved successfully.', true);
          this.setEditDialogVisibility({ visible: false });
          this.$nextTick(() => {
            this.hideStaffCostForm();
          });
        })
        .catch((error) => {
          this.$myalert.error(
            error.response?.data?.message || error.message || 'Failed to save staff cost. Please try again.',
            true
          );
        });
    },
    showStaffCostForm(staffType) {
      this.showAddNewRow = staffType;
      this.staffForm.districtRateType = this.invoiceConfig.defaultDistrictWideRate?.name || null;
      this.setHourlyRate({ form: this.staffForm, staff: this.currentStaff[staffType], staffType });
    },
    setHourlyRate({ form, staff, staffType }) {
      if (!form) return;

      let rate = 0;

      const districtWideRate = this.invoiceConfig.districtWideRates?.find(
        (rate) => rate.name === form.districtRateType
      );
      const overrideDriverRate =
        staffType === 'Driver' && this.invoiceDetailsStaffCost?.overrideDriverRate
          ? Number(this.invoiceDetailsStaffCost.tripEventDriverRate)
          : 0;

      const ratesMap = {
        districtWide: {
          ot: Number(districtWideRate?.otRate) || 0,
          regular: overrideDriverRate || Number(districtWideRate?.rate) || 0,
          other: Number(districtWideRate?.otherRate) || 0,
        },
        staff: {
          ot: staff?.otRate || 0,
          regular: overrideDriverRate || staff?.payRate || 0,
          other: staff?.otherRate || 0,
        },
      };

      rate = this.invoiceConfig.invoiceRate
        ? ratesMap.districtWide[form.rateType] || 0
        : ratesMap.staff[form.rateType] || 0;
      if (this.selectedInvoice.overrideDriverRate)
        rate = parseFloat(this.selectedInvoice.tripEventDriverRate).toFixed(2) ?? 0.0;

      form.hourlyRate = rate;
    },
    hideStaffCostForm() {
      this.showAddNewRow = null;
      this.setStaffCostDefault();
    },
    setStaffCostDefault() {
      this.staffForm.date = this.selectedInvoice.leaveDate.split('T')[0] ?? null;
      this.staffForm.startTime = null;
      this.staffForm.endTime = null;
      this.staffForm.hours =
        getTimeDifferenceInMinutes(
          this.staffForm.date,
          this.selectedInvoice.leaveTime,
          this.selectedInvoice.returnTime
        ) / 60;
      this.staffForm.rateType = 'regular';
      this.staffForm.hourlyRate = 0;
      this.staffForm.breaks = 0;
      this.staffForm.valid = false;
    },
    setDriverAssistantForm() {
      this.staff.Driver = this.invoiceDetailsStaffCost.driverId;
      this.staff.Assistant = this.invoiceDetailsStaffCost.assistantId;
    },
    getTotalBenefit(staffType) {
      const benefit = this.invoiceStaffBenefits[staffType.toLowerCase()];
      return Number(benefit.medical) + Number(benefit.ss) + Number(benefit.workerComp) + Number(benefit.retirement);
    },
  },
};
</script>

<style scoped>
.height-adjust {
  height: 130px;
}
</style>
