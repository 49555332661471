<template>
  <v-container fluid class="px-8 pt-0 relative">
    <section class="context-menu py-3">
      <v-row class="mb-4 mt-2">
        <v-col cols="12" md="6" class="d-flex py-0">
          <v-chip label class="button-chip">
            {{ invoicePaginationDetails.total }} {{ pluralize('Invoice', invoicePaginationDetails.total) }}
          </v-chip>
        </v-col>

        <v-spacer></v-spacer>

        <v-btn class="my-0 mx-2" dark color="primary" @click="print()" :loading="isPrinting">
          <v-icon>mdi-printer</v-icon>
        </v-btn>
        <v-switch
          class="ma-0 pa-0 mx-4 mt-2 d-inline-block"
          v-model="allExpanded"
          label="Expand All"
          hide-details
        ></v-switch>
      </v-row>

      <v-toolbar height="50" elevation="4" rounded v-if="!hideSubMenu">
        <InvoiceListMenu :filterText="filterText" />
      </v-toolbar>
    </section>

    <Table
      :current-page="invoicePaginationDetails.currentPage"
      :server-side="true"
      :headers="headers"
      :items-per-page="20"
      :loading="invoiceIsFetching"
      :menu-options="menuOptions"
      :rows="invoices"
      :total-items="invoicePaginationDetails.total"
      @pagination="setPagination"
      fixedHeader
      :height="tableHeight"
      :expand-row="allExpanded"
      class="mt-2 px-1"
    >
      <template v-slot:column_staffCost="{ item }">
        <span>{{ toDollarAmount(item.staffCost) }}</span>
      </template>

      <template v-slot:column_travelCost="{ item }">
        <span>{{ toDollarAmount(item.travelCost) }}</span>
      </template>

      <template v-slot:column_additionalCost="{ item }">
        <span>{{ toDollarAmount(item.additionalCost) }}</span>
      </template>

      <template v-slot:column_total="{ item }">
        <span>{{ toDollarAmount(item.total) }}</span>
      </template>

      <template v-slot:column_tripDate="{ item }">
        <div>
          {{ toDateString(item.leaveDate) }}
          <span v-if="!isSameDate(item.leaveDate, item.returnDate)">
            <v-icon small color="darken-2"> mdi-arrow-right </v-icon> {{ toDateString(item.returnDate) }}</span
          >
        </div>
      </template>

      <template v-slot:column_status="{ header, item }">
        <!-- Might be worth extracting this (chips dropdown) as a separate component when some other areas in the app needs it in the future-->
        <v-chip
          v-if="fundingSourceLimit > 1"
          :color="chipStatus({ header, item, type: 'color' })"
          small
          class="font-weight-bold"
          label
        >
          {{ chipStatus({ header, item, type: 'text' }) }}
        </v-chip>
        <v-select
          v-if="fundingSourceLimit === 1"
          class="invoice-status"
          chips
          :disabled="!canUpdateInvoiceStatus(item.status)"
          :hide-details="true"
          :hide-selected="true"
          :items="getFilteredInvoiceStatusOptions(item.status)"
          :value="item.status"
          @change="onChipStatusSelect($event, item)"
        >
          <template v-slot:selection>
            <v-chip :color="chipStatus({ header, item, type: 'color' })" small class="font-weight-bold" label>
              {{ chipStatus({ header, item, type: 'text' }) }}
              <v-avatar right v-if="canUpdateInvoiceStatus(item.status)">
                <v-icon>mdi-chevron-down</v-icon>
              </v-avatar>
            </v-chip>
          </template>
        </v-select>
      </template>

      <template v-slot:expansion="{ header, item }">
        <v-simple-table class="table-expanded-row">
          <thead>
            <tr>
              <th>Funding Source</th>
              <th>Budget Code</th>
              <th>Split Type</th>
              <th>Batch/Journal Number</th>
              <th>Check Number</th>
              <th>Amount</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="row in item.invoicePayments" :key="row.id" class="expanded-row-fit">
              <td>
                {{ row.fundingSource?.name ? row.fundingSource.name : 'N/A' }}
              </td>
              <td>{{ row.budgetCode ? row.budgetCode : 'N/A' }}</td>
              <td>
                {{ row.fundingSourceType ? row.fundingSourceType : 'N/A' }}
              </td>
              <td>{{ row.batchJournalNumber ? row.batchJournalNumber : 'N/A' }}</td>
              <td>{{ row.checkNumber ? row.checkNumber : 'N/A' }}</td>
              <td>
                <template v-if="row.fundingSourceType === 'percentage'">
                  {{ toDollarAmount(percentageValue({ amount: row.amount, total: item.total })) }}
                  {{ `(${row.amount}%)` }}
                </template>
                <template v-else>{{ toDollarAmount(row.amount) }}</template>
              </td>
              <td>
                <v-select
                  v-if="canUpdateInvoiceFundingStatus(item)"
                  class="invoice-status"
                  chips
                  :disabled="!canUpdateInvoicePaymentStatus(row)"
                  :hide-details="true"
                  :hide-selected="true"
                  :items="getFilteredInvoiceStatusOptions(row.status)"
                  :value="row.status"
                  @change="onFundingSourceChipStatusSelect($event, item, row)"
                >
                  <template v-slot:selection>
                    <v-chip
                      :color="chipStatus({ header, item: row, type: 'color' })"
                      small
                      class="font-weight-bold"
                      label
                    >
                      {{ chipStatus({ header, item: row, type: 'text' }) }}
                      <v-avatar right v-if="canUpdateInvoicePaymentStatus(row) && row.status !== 'paid'">
                        <v-icon>mdi-chevron-down</v-icon>
                      </v-avatar>
                    </v-chip>
                  </template>
                </v-select>
              </td>
            </tr>
          </tbody>
        </v-simple-table>
      </template>
    </Table>

    <InvoiceDrawer @close="hideInvoice" />
  </v-container>
</template>

<script>
import { percentageValue, pluralize, toDateString } from '@/util';
import { mapActions, mapGetters, mapMutations } from 'vuex';

import { Table } from '@/components/shared';
import InvoiceListMenu from '@/components/Invoice/menu/InvoiceMenu.vue';
import InvoiceDrawer from '@/components/Invoice/InvoiceDrawer.vue';
import { isEqual, parseISO } from 'date-fns';
import { toDollarAmount } from '@/util';

export default {
  components: { Table, InvoiceDrawer, InvoiceListMenu },
  props: {
    hideSubMenu: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Object,
      require: false,
      default: () => ({
        page: {
          currentPage: 1,
          pageSize: 20,
        },
      }),
    },
  },
  data() {
    return {
      headerClickHandlers: {
        tripRequestId: (item) => this.showInvoice(item),
      },
      menuOptions: [
        {
          text: 'Edit',
          click: (item) => this.showInvoice(item),
          icon: 'mdi-pencil',
        },
        {
          text: 'Print',
          click: (item) => this.print(item),
          icon: 'mdi-printer',
        },
      ],
      selected: [],
      invoiceDetailsArray: [],
      isPrinting: false,
      toDollarAmount,
      allExpanded: false,
      filterText: null,
    };
  },
  computed: {
    ...mapGetters('user', ['me']),
    ...mapGetters('invoice', [
      'canUpdateInvoiceStatus',
      'canUpdateInvoicePaymentStatus',
      'canUpdateInvoiceFundingStatus',
      'getFilteredInvoiceStatusOptions',
      'invoiceIsFetching',
      'invoicePaginationDetails',
      'invoiceReadOnly',
      'invoices',
      'invoicesTotal',
      'isInvoiceFilterDisabled',
      'statusOptions',
      'statusConfig',
      'invoiceColumns',
    ]),
    ...mapGetters('driver', ['drivers']),
    ...mapGetters('tripRequest', ['tripRequestsById']),
    ...mapGetters('config', ['tripRequestConfig']),
    headers() {
      let headers = this.invoiceColumns;

      if (['sm', 'xs'].includes(this.$vuetify.breakpoint.name)) {
        headers = this.invoiceColumns.filter((header) => ['tripRequestId', 'total', 'status'].includes(header.value));
      }

      return headers.reduce((acc, header) => {
        if (!header.show) {
          return acc;
        }
        if (header.value == 'data-table-expand' && this.fundingSourceLimit === 1) {
          return acc;
        }

        const clickHandler = this.headerClickHandlers[header.value];

        if (clickHandler) {
          acc.push({
            ...header,
            click: clickHandler,
          });

          return acc;
        }

        acc.push(header);

        return acc;
      }, []);
    },
    tableHeight() {
      return window.innerHeight - 300;
    },
    fundingSourceLimit() {
      return Number(this.tripRequestConfig.display?.fundingSourceLimit ?? 1);
    },
  },
  beforeMount() {
    if (this.isInvoiceFilterDisabled) return;

    const { fundingManager, schoolFinance, finance } = this.me.is;
    const status = fundingManager || schoolFinance ? 'approved' : finance ? 'sent' : 'pending';
    this.setInvoiceFilters({
      status,
      ...this.options.filter,
    });

    const tripRequestId = this.$route.query.tripRequestId;
    if (tripRequestId) {
      this.filterText = tripRequestId;
      this.handleNewTabFromTripRequest(tripRequestId);
    }
  },
  mounted() {
    this.getInvoices();
  },
  methods: {
    ...mapActions('invoice', [
      'getInvoices',
      'getInvoiceById',
      'getAttachments',
      'saveInvoice',
      'printInvoices',
      'saveInvoicePayment',
      'showInvoice',
      'hideInvoice',
    ]),
    ...mapMutations('invoice', ['setInvoiceFilters', 'setInvoicePagination']),
    toDateString,
    pluralize,
    percentageValue,
    async showDetails(item) {
      const [{ tripRequest }, invoice] = await Promise.all([
        this.getTripRequest(item.tripRequestId),
        this.getInvoiceById(item.id),
      ]);
      this.setCurrentTripRequest(tripRequest);
      this.setCurrentTripLocation(this.locationsById[tripRequest.locationId]);
      this.setInvoiceFilterDisabled(true);
      this.getAttachments(invoice.id);
      this.setSelectedInvoice(invoice);
    },
    hideDetails() {
      this.setInvoiceFilterDisabled(false);
      this.setSelectedInvoice(null);
      this.getInvoices();
    },
    setPagination({ itemsPerPage, page }) {
      const searchOptions = { ...this.invoicePaginationDetails.searchOptions };

      searchOptions.pageSize = itemsPerPage;
      searchOptions.currentPage = page;

      this.setInvoicePagination(searchOptions);
      this.getInvoices();
    },
    chipStatus({ item, header, type = 'text' }) {
      if (!header) {
        header = {
          render: () => {
            return this.statusConfig[item.status];
          },
        };
      }
      const headerValue = header.render(item);

      if (type === 'color') {
        return headerValue.color || '';
      }

      return headerValue.text;
    },
    onChipStatusSelect(e, item) {
      this.saveInvoice({
        invoiceId: item.id,
        body: { status: e },
      });
    },
    async onFundingSourceChipStatusSelect(e, item, row) {
      await this.saveInvoicePayment({
        invoiceId: item.id,
        body: { id: row.id, status: e },
      });
      this.getInvoices();
    },
    async print(item = null) {
      this.isPrinting = true;
      try {
        if (item) {
          await this.printInvoices({ invoiceIds: [item.id] });
          this.$myalert.success(
            'Your print request has been successfully processed. A PDF file will be generated and downloaded to your device shortly.',
            true
          );
          return;
        }

        const ids = this.invoices.map((i) => i.id);

        if (ids.length === 0) {
          this.$myalert.error('No invoices found. Check your filter settings.', true);
          return;
        }

        await this.printInvoices({ invoiceIds: ids });
        this.$myalert.success(
          'Your print request has been successfully processed. A PDF file will be generated and downloaded to your device shortly.',
          true
        );
      } catch (error) {
        this.$myalert.error('An error occurred while processing your print request.', true);
      } finally {
        this.isPrinting = false;
      }
    },
    isSameDate(date1, date2) {
      return isEqual(parseISO(date1), parseISO(date2));
    },
    handleNewTabFromTripRequest(id) {
      this.setInvoiceFilters({
        ['text']: id,
      });
    },
  },
  watch: {
    invoices(value) {
      if (!this.details || !this.details.item) return;

      // ensure that any changes on the list reflects the selected invoice
      this.details.item = value.find((i) => i.id === this.details.item.id);
    },
    options: {
      deep: true,
      immediate: true,
      handler(value) {
        if (this.isInvoiceFilterDisabled) return;

        this.setInvoiceFilters({
          status: this.me.is.fundingManager || this.me.is.schoolFinance ? 'approved' : 'pending',
          ...(value.filter || {}),
        });

        this.setInvoicePagination(value.page);
      },
    },
  },
  beforeDestroy() {
    this.hideInvoice();
  },
};
</script>

<style lang="scss">
.invoice-status {
  padding-top: 0 !important;
  margin-top: 0 !important;
  width: fit-content;

  .v-chip {
    cursor: pointer;
  }

  .v-input__control > .v-input__slot:before,
  .v-input__control > .v-input__slot:after,
  .v-input__append-inner,
  input {
    display: none !important;
  }
}
.button-chip {
  height: 36px !important;
  font-size: 16px !important;
}
.context-menu {
  position: sticky;
  top: 60px;
  z-index: 6;
  background-color: #eceff1;
}
.v-data-table.table-expanded-row {
  background-color: #e6e9f2;
  border-bottom: 5px solid rgb(167, 162, 162);
}
.expanded-row-fit td {
  width: 250px;
}
</style>
